"use client";

import React, { useEffect, useState } from "react";
import { GqlProductBySlugNodeInterface } from "@/lib/types/gql/product/product-by-slug.type";
import { ProductZoom } from "./product-zoom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import SwiperCore, { Navigation } from "swiper";
import SwiperClass from "swiper/types/swiper-class";
import { getDiscount } from "@/lib/utils";
import Badge from "@/components/Badge";
import ProductVideo from "./product-video";
import Placeholder from "../Placeholder";
import { Skeleton } from "../ui/skeleton";
import { useSearchParams } from "next/navigation";
SwiperCore.use([Navigation]);
type Props = {
  product: GqlProductBySlugNodeInterface | null;
};
const imageWh = 612;
const spaceBetween = 8;
const slidesPerView = 5;
const defaultImage = "/image/not-default.png"; // 设置默认图片路径
function ImageGallery({ product }: Props) {
  const searchParams = useSearchParams();
  const [active, setActive] = useState(0);
  const [mainImage, setMainImage] = useState<string>(defaultImage);
  const [images, setImages] = useState<string[]>([defaultImage]);
  const [swiperExample, setSwiperExample] = useState<SwiperClass>();
  const [discount, setDiscount] = useState<number | string>(0);
  // const { currentProduct } = useProductVariableStore();
  const [metaData, setMetaData] = useState<any>();
  const [playVideo, setPlayVideo] = useState<boolean>(false);
  const [slreview, setSlreview] = useState<number>(5)
  const [imageGalleryBtnId, setImageGalleryBtnId] = useState<number>(1);
  const changeProductImages = (product: GqlProductBySlugNodeInterface) => {
    if (product === null) {
      setImages([defaultImage]);
      setMainImage(defaultImage);
      setActive(0);
      return;
    }
    if (product?.image?.sourceUrl) {
      if (searchParams.get("index")) {
        // 首页点进来不要封面图
        setMainImage(product?.galleryImages.nodes[0]?.sourceUrl ?? product?.image?.sourceUrl);
      } else {
        setMainImage(product?.image?.sourceUrl);
      }
      if (product?.galleryImages?.nodes.length) {
        if (searchParams.get("index")) {
          // 首页点进来不要封面图
          setImages([...product?.galleryImages?.nodes?.map(i => i.sourceUrl)]);
        } else {
          setImages([product?.image?.sourceUrl, ...product?.galleryImages?.nodes?.map(i => i.sourceUrl)]);
        }

      } else {
        setImages([product?.image?.sourceUrl]);
      }
    } else {
      setImages([defaultImage]); // 设置默认图片
      setMainImage(defaultImage); // 设置默认图片
    }
    product && setDiscount(getDiscount(product.price, product.regularPrice));
  };
  useEffect(() => {
    console.log(searchParams.get("index"));
    // setSearchVal(searchParams.get("search"))
    if (searchParams.get("index") !== null) {
    }
  }, [searchParams.get("index")])
  useEffect(() => {
    if (product) {
      console.log("product---------------------", product);

      changeProductImages(product);
      setActive(0);
      setMetaData(() => {
        const metaData = product?.metaData || [];
        if (metaData && metaData.length) {
          return metaData.filter((i) => {
            if (i.key === "woodmart_wc_video_gallery" && i.value && typeof i.value === "string") {
              const value = JSON.parse(i.value);
              let valueObj: any;
              Object.keys(value).find((k) => {
                if (value[k].upload_video_url || value[k].vimeo_url || value[k].youtube_url) {
                  valueObj = value[k];
                  return true;
                }
              });
              i.value = valueObj;
              return i;
            }
          })[0];
        }
        return undefined;
      });
    }
  }, [product]);

  // useEffect(() => {
  //   if (currentProduct?.image?.sourceUrl) {
  //     setMainImage(currentProduct.image.sourceUrl);
  //     setImages((old) => {
  //       const newImages = [...old];
  //       newImages[0] = currentProduct?.image?.sourceUrl;
  //       return newImages;
  //     });
  //     setActive(0);
  //   } else if (product) {
  //     changeProductImages(product);
  //   }
  // }, [currentProduct]);
  const handlePrevOrNext = (type: "prev" | "next") => {
    if (type === "prev") {
      setActive((value) => {
        if (value === 0) return value;
        setMainImage(images[value - 1]);
        swiperExample?.slidePrev();
        return value - 1;
      });
    } else {
      setActive((value) => {
        if (value + 1 > images.length - 1) return value;
        setMainImage(images[value + 1]);
        if (value + 1 > slidesPerView - 1) {
          swiperExample?.slideNext();
        }
        return value + 1;
      });
    }
  };

  const setActiveImgIndex = (index: number) => {
    setActive((val) => {
      if (images.length > slidesPerView) {
        const prev = document.querySelector(".button-prev") as HTMLElement;
        const next = document.querySelector(".button-next") as HTMLElement;
        index > val ? next?.click() : prev?.click();
      }
      return index;
    });
  };

  const changeVideoUrl = (type: string): string => {
    return {
      youtube: metaData.value.youtube_url,
      mp4: metaData.value.upload_video_url,
      vimeo: metaData.value.vimeo_url,
    }[type];
  };
  useEffect(() => {
    if (active !== 0) {
      setPlayVideo(false);
    }
  }, [active]);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setSlreview(5)
      } else if (window.innerWidth > 768 && window.innerWidth < 1024) {
        setSlreview(4)
      } else if (window.innerWidth <= 768) {
        setSlreview(3)
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    // 在组件卸载时清除事件监听器
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // 空数组表示只在组件挂载和卸载时执行
  // 提取YouTube视频ID的函数
  const extractYouTubeId = (url: string) => {
    // console.log(url);
    // 尝试匹配 https://youtu.be/VIDEO_ID 格式
    const shortUrlMatch = url.match(/youtu\.be\/([a-zA-Z0-9_-]{11})/);
    if (shortUrlMatch && shortUrlMatch[1]) {
      return shortUrlMatch[1];
    }

    // 尝试匹配 https://www.youtube.com/watch?v=VIDEO_ID 或其他类似的参数形式
    const longUrlMatch = url.match(/[?&]v=([a-zA-Z0-9_-]{11})/);
    if (longUrlMatch && longUrlMatch[1]) {
      return longUrlMatch[1];
    }

    // 如果没有匹配到，返回null
    return null;
  };
  return (
    <div className="flex items-center flex-col select-none xl:w-[650px] lg:w-[550px] md:w-[400px]">
      {
        imageGalleryBtnId === 1 &&
        <div className="w-full c-flex relative">
          {mainImage && images.length > 1 && (
            <div
              onClick={() => {
                handlePrevOrNext("prev");
              }}
              className="absolute max-md:hidden md:mr-2  left-[20px] w-[48px] h-[48px] flex justify-center text-white
                            hover:bg-[rgba(0,0,0,.15)] transition-all z-[3] items-center top-1/2 cursor-pointer mt-[-24px] max-md:left-1 ri-arrow-left-s-line text-[24px] bg-[rgba(0,0,0,.25)] rounded-full"
            />
          )}
          <div
            style={{
              maxWidth: `${imageWh}px`,
              maxHeight: `${imageWh}px`,
            }}
            className="relative w-full h-fit"
          >
            {mainImage ? (
              <div className="w-full h-full group">
                <ProductZoom
                  src={mainImage}
                  imageWidth={imageWh}
                  imageHeight={imageWh}
                  setActiveImgIndex={setActiveImgIndex}
                  quality={100}
                  imgList={images}
                  fit="contain"
                />
                {!!discount && <Badge className="absolute z-[3] right-1 top-1">{discount}</Badge>}
                {/* {active === 0 && metaData && (
                <div
                  className="absolute z-[3] left-1/2 top-1/2 cursor-pointer translate-x-[-50%] translate-y-[-50%]"
                  onClick={() =>
                    setPlayVideo((old) => {
                      return !old;
                    })
                  }
                >
                  <i
                    className={`${playVideo ? "ri-pause-circle-fill hidden group-hover:block" : "ri-play-circle-fill"
                      } ri-4x text-white`}
                  ></i>
                </div>
              )}
              {metaData && (
                <ProductVideo
                  className={`absolute ${active === 0 && playVideo ? "" : "hidden"
                    } z-[2] left-1/2 top-1/2 cursor-pointer translate-x-[-50%] translate-y-[-50%]`}
                  type={metaData.value!.video_type as string}
                  url={changeVideoUrl(metaData.value!.video_type as string)}
                  play={playVideo}
                />
              )} */}
              </div>
            ) : (
              <Skeleton style={{ height: imageWh + "px", width: imageWh + "px" }} />
            )}
          </div>
          {mainImage && images.length > 1 && (
            <div
              onClick={() => {
                handlePrevOrNext("next");
              }}
              className="absolute max-md:hidden md:ml-2 right-[20px] max-md:right-1 w-[48px] h-[48px] hover:bg-[rgba(0,0,0,.15)] transition-all flex justify-center items-center top-1/2 cursor-pointer mt-[-24px]  ri-arrow-right-s-line  text-white text-[24px] bg-[rgba(0,0,0,.25)] rounded-full z-[3]"
            />
          )}
        </div>
      }
      <div
        style={{
          // maxWidth: `${imageWh}px`,
          // height: `${imageWh / 5}px`,
        }}
        className="w-full relative b-flex mt-3"
      >
        {images.length && imageGalleryBtnId === 1 ? (
          <>
            {/* {images.length > slidesPerView && (
              <>
                <div
                  onClick={() => {
                    handlePrevOrNext("prev");
                  }}
                  className={`button-prev cursor-pointer max-lg:left-0 z-[2] lg:z-[1] ri-arrow-left-s-line ri-3x text-main absolute left-[-50px]`}
                />
                <div
                  onClick={() => {
                    handlePrevOrNext("next");
                  }}
                  className={`button-next cursor-pointer max-lg:right-0 z-[2] lg:z-[1] ri-arrow-right-s-line ri-3x text-main absolute right-[-50px]`}
                />
              </>
            )} */}
            <Swiper
              onSwiper={setSwiperExample}
              className="w-full"
              spaceBetween={spaceBetween}
              slidesPerView={slreview}
            // navigation={
            //   {
            //     nextEl: ".button-next",
            //     prevEl: ".button-prev"
            //   }
            // }
            >
              {images?.map((image: any, index: number) => (
                <SwiperSlide key={index}>
                  <div
                    // h-[120px]
                    className={`cursor-pointer border-2  md:h-[100px] lg:h-[120px] max-md:h-[100px] max-sm:h-[110px]  overflow-hidden c-flex duration-300 ${active === index ? "border-main" : "border-themeSecondary200"
                      }`}
                    onMouseEnter={() => {
                      setMainImage(image);
                      setActive(index);
                    }}
                  >
                    <div className="hidden relative sm:flex sm:justify-center sm:items-center h-full">
                      {/* <Placeholder quality={100} src={image} width={100} height={100} /> */}
                      <img src={image} alt={process.env.NEXT_PUBLIC_COMPANY_NAME || "image"} loading="lazy" className="h-full w-full object-contain" />
                      {/* {index === 0 && metaData && (
                        <div className="absolute z-[1] left-1/2 top-1/2 cursor-pointer translate-x-[-50%] translate-y-[-50%]">
                          <i className="ri-play-circle-fill ri-2x text-white"></i>
                        </div>
                      )} */}
                    </div>
                    <div className="sm:hidden relative flex justify-center items-center h-full">
                      {/* <Placeholder quality={100} src={image} width={100} height={100} /> */}
                      <img src={image} alt={process.env.NEXT_PUBLIC_COMPANY_NAME || "image"} loading="lazy" className="h-full w-full object-contain" />
                      {/* {index === 0 && metaData && (
                        <div className="absolute z-[1] left-1/2 top-1/2 cursor-pointer translate-x-[-50%] translate-y-[-50%]">
                          <i className="ri-play-circle-fill ri-2x text-white"></i>
                        </div>
                      )} */}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        ) : product?.metaData && product?.metaData.length && product?.metaData[0]?.value && imageGalleryBtnId === 2 ?
          <div className="w-full md:h-[732px] max-md:h-[480px] bg-black flex items-center">
            {
              (product?.metaData?.find((m: any) =>
                m.value?.video_type === "mp4" ||
                m.value?.video_type === "youtube"
              )?.value as any)?.video_type === "mp4"
                ?
                <video
                  src={(product.metaData.find((m: any) => m.value?.video_type === "mp4")?.value as any)?.upload_video_url}
                  controls
                  className="w-full h-full"
                  preload="metadata"
                ></video>
                : (product?.metaData?.find((m: any) => (m.value as any)?.video_type === "youtube")?.value as any)?.video_type === "youtube"
                  ?
                  <iframe
                    className="w-full"
                    style={{ aspectRatio: '16 / 9' }}
                    src={`https://www.youtube.com/embed/${extractYouTubeId(
                      (product.metaData.find((m: any) => (m.value as any)?.video_type === "youtube")?.value as any)?.youtube_url
                    )}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen>
                  </iframe>
                  : ""
            }
          </div> : (
            imageGalleryBtnId === 1 && [1, 2, 3, 4, 5].map((_, index) => <Skeleton key={index} style={{ width: "80px", height: "80px" }} />)
          )}
      </div>
      {/* butoon tab */}
      <div className="my-10 flex justify-center gap-x-12 flex-wrap">
        <div className={`flex items-center gap-2 cursor-pointer rounded-full py-2 px-4 ${imageGalleryBtnId === 1 ? "bg-main" : ""}`} onClick={() => setImageGalleryBtnId(1)}>
          <img src={`${imageGalleryBtnId === 1 ? "/image/photos-active.png" : "/image/photos.png?v=2"}`} alt="" className="w-[20px] h-[20px] object-contain" />
          <span className={`text-[#333] text-[14px] ${imageGalleryBtnId === 1 ? "text-white" : ""}`}>Photos</span>
        </div>
        {
          product?.metaData && product?.metaData.length && product?.metaData[0]?.value && <div className={`flex items-center gap-2 cursor-pointer rounded-full py-2 px-4 ${imageGalleryBtnId === 2 ? "bg-main" : ""}`} onClick={() => setImageGalleryBtnId(2)}>
            <img src={`${imageGalleryBtnId === 2 ? "/image/play-active.png" : "/image/play.png"}`} alt="" className="w-[14px] h-[14px] object-contain" />
            <span className={`text-[#333] text-[14px] ${imageGalleryBtnId === 2 ? "text-white" : ""}`}>Showreel</span>
          </div>
        }
      </div>
    </div>
  );
}

export default React.memo(ImageGallery);
