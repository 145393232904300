"use client";
import { useProductVariableStore } from "@/store/product-variable.store";
import {
    GqlProductAttributeNodeInterface,
    GqlProductBySlugNodeInterface,
} from "@/lib/types/gql/product/product-by-slug.type";
import Price from "../Price/price";
type Props = {
    product: GqlProductBySlugNodeInterface | null;
};
export default function CurrentProductPrice({ product }: Props) {
    const { currentProduct } = useProductVariableStore();
    return <>
        {/*这里展示选中的变体价格*/}
        {currentProduct?.price && (
            <div>
                <Price price={currentProduct?.price || ""} className="text-3xl font-bold" />
                {currentProduct.price !== product?.regularPrice && (
                    <Price
                        price={currentProduct?.regularPrice || ""}
                        className="text-themeSecondary400 ml-2 text-xs font-normal line-through sm:text-sm"
                    />
                )}
            </div>
        )}
    </>
}