import { usePathname } from "next/navigation";
import React from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

export const SocialShare = () => {
  const pathname = usePathname();

  const fullUrl = `${process.env.NEXT_PUBLIC_SITE_URL}${pathname}`;

  return (
    <div className="flex gap-4">
      {/* <div className="text-themeSecondary900 text-lg">Share On:</div> */}
      <div className="flex gap-2">
        <FacebookShareButton url={fullUrl}>
          <FacebookIcon size={26} round />
        </FacebookShareButton>
        <TwitterShareButton url={fullUrl}>
          <TwitterIcon size={26} round />
        </TwitterShareButton>
        <LinkedinShareButton url={fullUrl}>
          <LinkedinIcon size={26} round />
        </LinkedinShareButton>
        <WhatsappShareButton url={fullUrl}>
          <WhatsappIcon size={26} round />
        </WhatsappShareButton>
      </div>
    </div>
  );
};
