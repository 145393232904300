"use client"
import { GqlProductBySlugNodeInterface } from "@/lib/types/gql/product/product-by-slug.type";
import Calculator from "@/components/Calculator";
import React, { useEffect, useState } from "react";
import { useProductVariableStore } from "@/store/product-variable.store";
import { useProductAttributeStore } from "@/store/product-attribute.store";
import { AddToCartButton } from "../ShoppingCart/addToCart";
import { useCalculatorStore } from "@/store/calculator.store"
export default function ProductCartBuyInquiry(props?: GqlProductBySlugNodeInterface) {
  // const [count, setCount] = useState(1);
  const { currentProduct } = useProductVariableStore();
  const { selectAttribute } = useProductAttributeStore();
  const { showCalculator = true, showBtnGroup = true } = props || {};
  const { count } = useCalculatorStore();
  return (
    <>
      {/* <div className="border border-themeSecondary200 w-full mt-5" /> */}
      <div className="">
        <div className="s-flex gap-x-4 flex-wrap gap-y-4 max-md:w-full">
          {/* <Calculator
            initCount={1}
            className="w-[45px]"
            changeCount={(c) => {
              setCount(c);
            }}
            minCount={1}
            maxCount={props?.variations ? currentProduct?.stockQuantity : props?.stockQuantity}
          /> */}
          <div className={`c-flex max-md:w-full gap-x-4 max-md:flex-wrap gap-y-4 ${showBtnGroup ? "" : "!hidden"}`}>
            {props?.variations ? (
              <>
                <AddToCartButton<"VariableProduct">
                  // 什么时候禁用？
                  // 当前产品不存在
                  // 库存不存在
                  // 库存状态无货
                  // 产品id不存在
                  // 产品没有价格
                  loadingColor="#3eb036"
                  className="!bg-white !text-main border !border-main !rounded-full !px-14 !py-3 max-md:!w-full"
                  disabled={
                    !currentProduct ||
                    !currentProduct?.stockQuantity ||
                    currentProduct?.stockStatus !== "IN_STOCK" ||
                    !currentProduct?.databaseId ||
                    !currentProduct?.price
                  }
                  __typename={"VariableProduct"}
                  product={{
                    databaseId: currentProduct?.databaseId.toString() || "",
                    quantity: count.toString(),
                    stockQuantity: currentProduct?.stockQuantity,
                    stockStatus: currentProduct?.stockStatus || "OUT_OF_STOCK",
                    attributes: props?.attributes?.nodes || [],
                    variation: currentProduct?.attributes.nodes || selectAttribute,
                  }}
                />
                <AddToCartButton<"VariableProduct">
                  // 什么时候禁用？
                  // 当前产品不存在
                  // 库存不存在
                  // 库存状态无货
                  // 产品id不存在
                  // 产品没有价格
                  className="!rounded-full !px-16 !py-3 max-md:!w-full"
                  disabled={
                    !currentProduct ||
                    !currentProduct?.stockQuantity ||
                    currentProduct?.stockStatus !== "IN_STOCK" ||
                    !currentProduct?.databaseId ||
                    !currentProduct?.price
                  }
                  __typename={"VariableProduct"}
                  buyNow={true}
                  product={{
                    databaseId: currentProduct?.databaseId.toString() || "",
                    quantity: count.toString(),
                    stockQuantity: currentProduct?.stockQuantity,
                    stockStatus: currentProduct?.stockStatus || "OUT_OF_STOCK",
                    attributes: props?.attributes?.nodes || [],
                    variation: currentProduct?.attributes.nodes || selectAttribute,
                  }}
                />
              </>
            ) : (
              <>
                <AddToCartButton<"SimpleProduct">
                  disabled={
                    (!!props?.attributes?.nodes?.length
                      ? selectAttribute.length !== props?.attributes?.nodes?.length
                      : false) ||
                    !props ||
                    // !props?.stockQuantity ||
                    props?.stockStatus !== "IN_STOCK" ||
                    !props?.databaseId ||
                    !props?.price
                  }
                  loadingColor="#3eb036"
                  className="!bg-white !text-main border !border-main !rounded-full !px-14 !py-3 max-md:!w-full"
                  __typename={"SimpleProduct"}
                  product={{
                    databaseId: props?.databaseId.toString() || "",
                    quantity: count.toString(),
                    stockQuantity: props?.stockQuantity,
                    stockStatus: props?.stockStatus || "OUT_OF_STOCK",
                    attributes: props?.attributes?.nodes || [],
                    item_data: (() => {
                      let item_data: Record<string, string> = {};
                      selectAttribute.map((item) => {
                        item_data[item.name] = item.value;
                      });
                      return item_data;
                    })(),
                  }}
                />
                <AddToCartButton<"SimpleProduct">
                  disabled={
                    (!!props?.attributes?.nodes?.length
                      ? selectAttribute.length !== props?.attributes?.nodes?.length
                      : false) ||
                    !props ||
                    // !props?.stockQuantity ||
                    props?.stockStatus !== "IN_STOCK" ||
                    !props?.databaseId ||
                    !props?.price
                  }
                  __typename={"SimpleProduct"}
                  className="!rounded-full !px-16 !py-3 max-md:!w-full"
                  buyNow={true}
                  product={{
                    databaseId: props?.databaseId.toString() || "",
                    quantity: count.toString(),
                    stockQuantity: props?.stockQuantity,
                    stockStatus: props?.stockStatus || "OUT_OF_STOCK",
                    attributes: props?.attributes?.nodes || [],
                    item_data: (() => {
                      let item_data: Record<string, string> = {};
                      selectAttribute.map((item) => {
                        item_data[item.name] = item.value;
                      });
                      return item_data;
                    })(),
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
      {/* <div className=" border border-themeSecondary200 w-full mt-5"></div> */}
    </>
  );
}
