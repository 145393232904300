"use client";

import {
  GqlProductAttributeNodeInterface,
  GqlProductBySlugNodeInterface,
} from "@/lib/types/gql/product/product-by-slug.type";
import { useEffect, useRef, useState } from "react";
import { useProductAttributeStore } from "@/store/product-attribute.store";
import { useProductVariableStore } from "@/store/product-variable.store";
import Price from "../Price/price";
import ProductAttributes from "./product-attributes";
import ProductVariable from "./product-variable";
import { BodyText } from "../BodyText";
import Badge from "../Badge";
import ProductCartBuyInquiry from "./product-cart-buy-inquiry";
import Link from "next/link";
import { SocialShare } from "../SocialShare";
import { AddToCartButton } from "../ShoppingCart/addToCart";
import ProductStock from "./productStock";
import { Disclosure } from "@headlessui/react";
import { Rate } from 'antd';
import Calculator from "@/components/Calculator";
import { useCalculatorStore } from "@/store/calculator.store"
type Props = {
  product: GqlProductBySlugNodeInterface | null;
};

export type ChangeValueType = {
  key: string;
  value: string;
  id?: number;
  label: string;
};

const icons = [
  {
    title: "product.pi2",
    img: "/image/pi2.webp",
  },
  {
    title: "product.pi1",
    img: "/image/pi1.webp",
  },
  {
    title: "product.pi3",
    img: "/image/pi3.webp",
  },
];

const faqs = [
  {
    question: "Warnings & CA Prop65",
    answer: `⚠️ WARNING:<br />This product can expose you to chemicals including Chromium (Hexavalent Compounds), which are known to the State of California to cause cancer and birth defects or other reproductive harm. For more information, go to <a className="hover:text-main" href="https://www.P65Warnings.ca.gov" target="_blank" title="https://www.P65Warnings.ca.gov" aria-label="www.P65Warnings.ca.gov - Opens in a new tab ">www.P65Warnings.ca.gov</a>`,

    // "WARNING:This product can expose you to chemicals including Chromium (Hexavalent Compounds), which are known to the State of California to cause cancer and birth defects or other reproductive harm. For more information, go to www.P65Warnings.ca.gov"
  },
];

export default function Information({ product }: Props) {
  const { setSelectAttribute, removeSelectAttribute } = useProductAttributeStore();
  const { changeCount } = useCalculatorStore();
  const handlerAttribute = ({ key, value, id, label }: any) => {
    setSelectAttribute(key, value, id!, label);
  };
  const { currentProduct } = useProductVariableStore();
  useEffect(() => {
    return () => removeSelectAttribute();
  }, []);

  return (
    <div className="lg:w-[500px] md:flex-1">
      <h1 className="text-themeSecondary800 md:leading-10 text-3xl max-md:mt-4 text-[#333] font-semibold  mb-4">{product?.name}</h1>
      <div className="mb-4">
        <div className="flex gap-2">
          {product ? (
            <>
              <div className="flex justify-center items-center rft">
                {/* 动态渲染五角星 */}
                {/* <BodyText className="flex items-center gap-1">
                  {Array.from({ length: 5 }).map((_, index) => {
                    const ratingValue = index + 1; // 当前星星的值（1到5）
                    if (ratingValue <= Math.floor(product?.averageRating || 0)) {
                      // 完整星星
                      return (
                        <i
                          key={index}
                          className="ri-star-s-fill ri-xl text-main !text-[30px]"
                        ></i>
                      );
                    } else if (
                      ratingValue - 0.5 === Math.ceil(product?.averageRating || 0)
                    ) {
                      // 半星
                      return (
                        <i
                          key={index}
                          className="ri-star-half-s-fill ri-xl text-main !text-[30px]"
                        ></i>
                      );
                    } else {
                      // 空星
                      return (
                        <i
                          key={index}
                          className="ri-star-line ri-xl !text-[30px]"
                        ></i>
                      );
                    }
                  })}
                </BodyText> */}
                <Rate allowHalf defaultValue={product?.averageRating || 0} disabled className="text-main text-xl" />
                <span className="font-semibold text-xl ml-2 mr-3">
                  {product?.averageRating}
                </span>
                <BodyText className="text-themeSecondary800 !text-lg font-normal underline">
                  {product?.reviewCount || 0} reviews
                </BodyText>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div className="s-flex flex-wrap gap-2 mb-4">
        <div className="c-flex gap-2">
          {product ? (
            <>
              <Price price={product?.price || ""} className="text-2xl text-main font-semibold !py-0" />
              {!product.variations && product.price !== product.regularPrice && (
                <Price
                  price={product?.regularPrice || ""}
                  className="text-themeSecondary400 text-xs font-normal line-through sm:text-sm"
                />
              )}
              <div className="bg-themeSecondary200 hidden h-8 w-0.5 md:block"></div>
            </>
          ) : null}
        </div>
        <div className="c-flex gap-2">
          {product ? (
            product?.stockStatus ? (
              <Badge
                className={`${(currentProduct?.stockStatus || product?.stockStatus) === "OUT_OF_STOCK" ? "bg-themeWarning500" : ""
                  }`}
              >
                {(currentProduct?.stockStatus || product?.stockStatus)?.toLowerCase()?.replace(/_/g, " ")}
              </Badge>
            ) : (
              ""
            )
          ) : null}
        </div>
      </div>
      {product
        ? product?.shortDescription && (
          <>
            {/* <div className="border-themeSecondary200 mt-5 w-full border" /> */}
            <div className="bg-[#f6f8f9] p-4 rounded-lg features-wrap">
              {/* <BodyText
                className="font-bold !text-xl mb-4"
              >Features</BodyText> */}
              <div className="font-bold text-[22px] mb-2">Features</div>
              {/* <BodyText
                className="font-normal text-[#0c0c0c] text-base"
              >
                {(product?.shortDescription).replace(/<[^>]*>/g, "").trim() || ""}
              </BodyText> */}
              <p className="font-normal text-[#0c0c0c] text-base" dangerouslySetInnerHTML={{ __html: product?.shortDescription || "" }}></p>
            </div>
            {/* <div className="border-themeSecondary200 mt-5 w-full border" /> */}
          </>
        )
        : null}
      <div>
        <div className="my-6 flex items-center justify-between flex-wrap">
          <span className="text-[18px] text-[#383838] font-bold">Pay with Ease</span>
          <div className="flex items-center gap-1 md:max-w-[280px] flex-wrap">
            {/* <img src={"/image/Frame40119.png"} width={65} alt="" />
            <img src={"/image/Frame40120.png"} width={65} alt="" />
            <img src={"/image/Frame40121.png"} width={65} alt="" />
            <img src={"/image/Frame40122.png"} width={65} alt="" />
            <img src={"/image/Frame40128.png"} width={65} alt="" /> */}
            {/* <AirwallexIcon /> */}
            {Array.from({ length: 10 }).map((item, index) => {
              return (
                <img
                  key={index}
                  src={`/image/payment/${index + 1}.svg`}
                  alt=""
                  className="h-[30px] w-[50px]"
                />
              );
            })}
          </div>
        </div>

        {/* <div className="rounded-lg bg-[#f6f8f9] p-4 my-4">
          <span className="rounded-sm border-[1px] border-main px-1 mb-2 inline-block">
            <span className="!text-[12px] text-main">Category</span>
          </span>
          {product?.productCategories?.edges?.map((edge, index) => {
            return (
              <div key={index} className="text-[#3b3e40]">
                <Link href={`/${edge?.node?.slug}`} className="text-themeSecondary600 text-xs font-normal sm:text-sm">
                  {edge?.node?.name}
                </Link>
              </div>
            );
          })}
        </div> */}
        {/* <div className="my-2 s-flex">
          Stock:
          <BodyText className="mx-1">
            <ProductStock product={product} />
          </BodyText>
        </div> */}
        {/* <div className="border-themeSecondary200 mt-5 w-full border"></div> */}
        {/* <div className="border-themeSecondary200 mb-5 w-full border"></div> */}
        <div className="my-6 flex items-center justify-between flex-wrap">
          <span className="text-[18px] text-[#383838] font-bold">Share on</span>
          <SocialShare />
        </div>
        <div className="my-6">
          {/* {faqs.map((faq) => (
            <Disclosure as="div" key={faq.question}>
              {({ open }) => (
                <>
                  <dt>
                    <Disclosure.Button className="flex w-full items-start justify-between text-left">
                      <span className="text-lg">{faq.question}</span>
                      <span className="ml-6 flex h-7 items-center">
                        {open ? (
                          <i className="ri-arrow-up-s-line ri-xl font-bold"></i>
                        ) : (
                          <i className="ri-arrow-down-s-line ri-xl font-bold"></i>
                        )}
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2">
                    <p className="text-[14px]" dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))} */}
          <span className="text-[18px] text-[#383838] font-bold">{faqs[0].question}</span>
          <p className="mt-2 font-normal text-[#0c0c0c] text-[14px]" dangerouslySetInnerHTML={{ __html: faqs[0].answer }}></p>
        </div>
        {product ? (
          !product?.variations?.nodes?.length ? (
            product?.attributes?.nodes?.map((atr, index) => {
              return (
                <div key={index} className="my-4">
                  {/* {!product?.shortDescription && <div className="border-themeSecondary200 mt-5 w-full border" />} */}
                  {/* <span className="!text-[12px] text-main">Attribute</span> */}
                  <ProductAttributes {...atr} changeValue={handlerAttribute} />
                </div>
              );
            })
          ) : (
            <div className="my-6">
              {/* <span className="rounded-sm border-[1px] border-main px-1 inline-block mb-2">
                <span className="!text-[12px] text-main">Attribute</span>
              </span> */}
              {/* {!product?.shortDescription && <div className="border-themeSecondary200 mt-5 w-full border" />} */}
              <ProductVariable product={product} changeValue={handlerAttribute} />
              {/*这里展示选中的变体价格*/}
              {/* {currentProduct?.price && (
                <div>
                  <Price price={currentProduct?.price || ""} className="text-xl font-bold" />
                  {currentProduct.price !== product?.regularPrice && (
                    <Price
                      price={currentProduct?.regularPrice || ""}
                      className="text-themeSecondary400 ml-2 text-xs font-normal line-through sm:text-sm"
                    />
                  )}
                </div>
              )} */}
            </div>
          )
        ) : null}
      </div>
      <Calculator
        initCount={1}
        className="w-[45px]"
        changeCount={(c) => {
          changeCount(c)
        }}
        minCount={1}
        maxCount={product?.variations ? currentProduct?.stockQuantity : product?.stockQuantity}
      />
      {/* <ProductCartBuyInquiry showBtnGroup={false} {...product!} /> */}
    </div>
  );
}

const AirwallexIcon = () => {
  return (
    <svg width="175" height="18" viewBox="0 0 175 24">
      <defs>
        <path
          d="M150.743 11.045c2.213 0 3.066 1.354 3.185 2.533a.128.128 0 0 1-.128.14h-6.077a.128.128
           0 0 1-.127-.144c.164-1.152 1.185-2.529 3.147-2.529zm3.331 7.496a.254.254 0 0 0-.207.105c-.548.772-1.44
            1.248-2.772 1.248-1.835 0-3.428-1.206-3.6-2.915a.128.128 0 0 1 .127-.14h10.919c.031-.096.095-.828.095-1.497
             0-5.092-3.036-8.116-7.957-8.116-4.122 0-7.925 3.246-7.925 8.339 0 5.316 3.899 8.435 8.277 8.435 3.957 0
              6.464-2.214 7.277-4.89.005-.016.032-.116.062-.262a.255.255 0 0 0-.25-.307h-4.046zm9.655-3.057l-5.177-7.38a.255.255
               0 0 1 .21-.401h5.09c.087 0 .167.043.215.115l2.66 4.05c.05.077.164.076.214-.002l2.599-4.045a.255.255 0 0 1 
               .215-.118h4.798c.208 0 .329.233.21.402l-5.108 7.186a.254.254 0 0 0-.001.293c1.672 2.38 3.583 5.13 5.298
                7.537.12.169 0 .401-.208.401h-5.055a.256.256 0 0 1-.214-.114l-2.758-4.182a.128.128 0 0 0-.213 0c-.826 
                1.228-1.906 2.938-2.725 4.182a.255.255 0 0 1-.214.114h-4.712a.254.254 0 0 1-.21-.399l5.087-7.349a.254.254
                 0 0 0 0-.29zm-27.43 7.784V.733c0-.141.115-.255.256-.255h4.346c.141 0 .256.114.256.255v22.535c0 .14-.115.254-.256.254h-4.346a.255.255
                  0 0 1-.256-.254zm-7.158 0V.733c0-.141.115-.255.256-.255h4.346c.141 0 .255.114.255.255v22.535c0 .14-.114.254-.255.254h-4.346a.255.255 
                  0 0 1-.256-.254zm-10.748-3.47c1.95 0 3.611-1.527 3.611-4.201 0-2.737-1.63-4.17-3.611-4.17-2.077 0-3.643 1.433-3.643 4.17 0 2.61
                   1.63 4.202 3.643 4.202zm3.643 1.687c-.703 1.528-2.3 2.483-4.282 2.483-4.666 0-7.893-3.533-7.893-8.403 0-4.71 3.036-8.34 
                   7.733-8.34 2.844 0 4.09 1.56 4.41 2.292v-1.56c0-.14.114-.254.256-.254h4.186c.14 0 .255.114.255.255v15.31c0 
                   .14-.114.254-.255.254h-4.183a.255.255 0 0 1-.255-.258c.008-.575.028-1.904.028-1.779zM99.496 7.878l2.911 8.818c.04.12.21.116.244-.005l2.487-8.802a.255.255 
                   0 0 1 .246-.186h4.22c.173 0 .296.166.245.33l-4.763 15.31a.255.255 0 0 1-.244.18h-4.453a.256.256 0 0 1-.242-.174l-3.27-9.682c-.04-.116-.205-.115-.243 0l-3.21 9.68a.255.255 0 
                   0 1-.242.175h-4.549a.256.256 0 0 1-.244-.178l-4.825-15.31a.255.255 0 0 1 .244-.331h4.508c.114 0 .215.076.246.186l2.487 8.774c.034.12.204.124.244.005l2.942-8.79a.256.256 0 0
                    1 .243-.175h4.775c.11 0 .209.07.243.175zm-17.25 4.287a.255.255 0 0 1-.299.251 7.027 7.027 0 0 0-1.235-.098c-1.95 0-3.707 1.146-3.707 4.297v6.653c0 .14-.114.254-.255.254h-4.346a.255.255 0
                     0 1-.256-.254V7.958c0-.14.114-.255.256-.255h4.186c.141 0 .255.114.255.255v1.878c.831-1.783 2.845-2.292 4.123-2.292.388 0 .776.042 1.079.108.117.026.199.13.199.249v4.264zM64.894
                      23.268V7.958c0-.14.115-.255.256-.255h4.346c.141 0 .256.114.256.255v15.31c0 .14-.115.254-.256.254H65.15a.255.255 0 0 1-.256-.254zM67.291.032c1.598 0 2.876 1.273 2.876 2.833 0
                       1.56-1.278 2.833-2.876 2.833-1.534 0-2.812-1.273-2.812-2.833 0-1.56 1.278-2.833 2.812-2.833zM49.417 14.355h5.136c.088 0 .15-.086.12-.169L52.137 6.9a.128.128 0 0 0-.242-.001l-2.597
                        7.287c-.03.082.032.17.12.17zm6.733 4.584h-8.395a.255.255 0 0 0-.24.17l-1.51 4.244a.255.255 0 0 1-.241.17H41.01a.255.255 0 0 1-.24-.345L49.11 1.12a.255.255 0 0 1 .239-.165h5.494c.106 0
                         .202.066.239.166l8.246 22.058a.255.255 0 0 1-.24.343h-4.947a.256.256 0 0 1-.241-.17l-1.51-4.243a.256.256 0 0 0-.24-.17z"
          id="405478f4-c9e0-4e94-baa9-4803f6023b66"
        ></path>
        <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="dfe256f8-af81-4062-b09e-7b13cce30813">
          <stop stopColor="#FF4244" offset="0%"></stop>
          <stop stopColor="#FF8E3C" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <use fill="#000" href="#405478f4-c9e0-4e94-baa9-4803f6023b66"></use>
          <path
            d="M34.614 9.421a4.452 4.452 0 0 1 1.057 4.77l-2.347 6.376c-.616 1.674-2.02 2.969-3.755 3.307a4.882 4.882 0 0 1-4.732-1.69L10.763 5.322a.31.31 0 0 0-.528.093L5.656 
        17.8c-.095.256.157.504.407.402l5.619-2.295a2.481 2.481 0 0 1 3.296 1.546c.415 1.273-.283 2.648-1.512 3.15L6.126 23.6c-1.359.555-2.92.457-4.144-.36a4.461 4.461 0
         0 1-1.704-5.26l5.41-14.628C6.329 1.618 7.789.394 9.594.078a5.025 5.025 0 0 1 4.768 1.755l8.078 9.68 7.43-3.035c1.651-.674 3.469-.313 4.744.943zm-4.285 
         4.862c.094-.256-.158-.504-.408-.401l-4.105 1.676 2.462 2.951a.31.31 0 0 0 .53-.093l1.52-4.133z"
            fill="url(#dfe256f8-af81-4062-b09e-7b13cce30813)"
          ></path>
        </g>
      </g>
    </svg>
  );
};
